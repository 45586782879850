import React from 'react';
import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
import MyIcon from '../components/MyIcon';

class OurMarket extends React.PureComponent {
  render() {
    return (
      <div className="ourmarket">
        <div className="ourmarket_wrapper">
          <div className="title">多种营销工具 拉新促活大有可为</div>
          <div className="subtitle">
            玄掌柜支持当前各种主流的营销方式，拉新-转化-留存覆盖会员全生命周期，也能快速响应热门促销方式，拥抱变化
          </div>
          <div className="content">
            <Row className="content_wrapper" gutter={this.props.isMobile ? 10 : 30}>
              {[
                {
                  t: '拉新客',
                  children: [
                    { t: '好友助力', icon: 'iconwodezhuli' },
                    { t: '拼团/砍价', icon: 'iconwodepintuan1' },
                    { t: '票卡转增', icon: 'iconfuwu1' },
                  ],
                },
                {
                  t: '促转化',
                  children: [
                    { t: '优惠券', icon: 'iconkeyongxiangmu' },
                    { t: '特价商品', icon: 'iconzongjia' },
                    { t: '秒杀/抢购', icon: 'iconwodeyuyue' },
                  ],
                },
                {
                  t: '提客单',
                  children: [
                    { t: '多次卡/年卡', icon: 'iconSKJYDDSJTJ' },
                    { t: '组合套餐', icon: 'icontaocan1' },
                    { t: '满减/赠', icon: 'iconmanjian' },
                  ],
                },
                {
                  t: '高留存',
                  children: [
                    { t: '储值有礼', icon: 'icontaocan' },
                    { t: '积分商城', icon: 'iconjifen' },
                    { t: '会员等级体系', icon: 'iconshengji-' },
                  ],
                },
              ].map((s, i) => (
                <Col xs={12} sm={6} md={6} lg={6} xl={6} key={s.t} className={`item item_${i}`}>
                  <div className="head">{s.t}</div>
                  {s.children.map(p => (
                    <div className="subitem" key={p.t}>
                      <div className="left">
                        <MyIcon
                          className="icon"
                          type={p.icon}
                          style={{ fontSize: this.props.isMobile ? 23 : 36, color: '#fff' }}
                        />
                      </div>
                      <div className="right">{p.t}</div>
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OurMarket;
