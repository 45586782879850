import React from 'react';
import { Row, Col } from 'antd';
// import TweenOne, { TweenOneGroup } from 'rc-tween-one';
// import { Element } from 'rc-banner-anim';
// import { isImg } from './utils';
import 'rc-banner-anim/assets/index.css';
import MyIcon from '../components/MyIcon';

// const { BgElement } = Element;
class Banner extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    // const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
    //   const elem = item.BannerElement;
    //   const elemClassName = elem.className;
    //   delete elem.className;
    //   const { bg, textWrapper, title, content, button } = item;
    //   return (
    //     <Element key={i.toString()} {...elem} prefixCls={elemClassName}>
    //       <BgElement key="bg" {...bg} />
    //       <QueueAnim type={['bottom', 'top']} delay={200} key="text" {...textWrapper}>
    //         <div key="logo" {...title}>
    //           {typeof title.children === 'string' && title.children.match(isImg) ? (
    //             <img src={title.children} width="100%" alt="img" />
    //           ) : (
    //             title.children
    //           )}
    //         </div>
    //         <div key="content" {...content}>
    //           {content.children}
    //         </div>
    //         <Button ghost key="button" {...button}>
    //           {button.children}
    //         </Button>
    //       </QueueAnim>
    //     </Element>
    //   );
    // });
    console.log(this.props);
    return (
      <div {...props} {...dataSource.wrapper}>
        <div className="content">
          <Row gutter={20}>
            <Col xs={24} sm={16} md={16} lg={16} xl={16} span={16}>
              <div>
                <p className="title">降低获客成本、助力坪效提升</p>
                <p className="title">的门店运营管理系统</p>
              </div>
              <div>
                <p className="desc">
                  玄掌柜门店运营管理系统，提供线上线下联动的多渠道营销工具以及随时随地方便管理的产品矩阵，降本增效，打造会赚钱的门店运营管理系统。
                </p>
              </div>
            </Col>
            <Col xs={0} sm={8} md={8} lg={8} xl={8} span={8}>
              <img
                alt=""
                className="img"
                width="100%"
                src="https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/weapp.jpg"
              />
            </Col>
          </Row>
          <div className="store_type">
            <Row gutter={20}>
              {[
                { icon: 'iconyundonghuwai', name: '亲子游乐' },
                { icon: 'iconDIY', name: '手工DIY' },
                { icon: 'icongaoxiaoquwei', name: '电玩娱乐' },
                { icon: 'iconmuying', name: '母婴零售' },
                { icon: 'iconmeifa', name: '美容美体' },
                { icon: 'icondianpu', name: '连锁门店' },
              ].map(s => (
                <Col xs={8} sm={4} md={4} lg={4} xl={4} span={4} key={s.name}>
                  <div className="item">
                    <MyIcon
                      className="icon"
                      type={s.icon}
                      style={{ fontSize: this.props.isMobile ? 32 : 64, color: '#FFA100' }}
                    />
                    <p className="name">{s.name}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
