import React from 'react';
import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
import MyIcon from '../components/MyIcon';

function Left(props) {
  return (
    <div className="left">
      <div className="left_content">
        <p className="head">{props.head}</p>
        <p className="desc">{props.desc}</p>
      </div>
      <div className="icon">
        <MyIcon
          className="icon"
          type={props.icon}
          style={{ fontSize: props.isMobile ? 25 : 50, color: '#338BF9' }}
        />
      </div>
    </div>
  );
}

function Right(props) {
  return (
    <div className="right">
      <div className="icon">
        <MyIcon
          className="icon"
          type={props.icon}
          style={{ fontSize: props.isMobile ? 25 : 50, color: '#338BF9' }}
        />
      </div>
      <div className="right_content">
        <p className="head">{props.head}</p>
        <p className="desc">{props.desc}</p>
      </div>
    </div>
  );
}

class OnlineOutline extends React.PureComponent {
  render() {
    const data = [
      {
        t: '微信小程序商城',
        d: '通过微信提供线上会员服务、商品销售、服务预约等功能，全渠道创造会员接触点。',
        i: 'iconshangdianfeicanyin',
      },
      {
        t: '老板/店员小程序',
        d: '实时查看门店营收数据；店员可进行商品分销，全面提升生产力。',
        i: 'iconrenshiguanli',
      },
      {
        t: 'WEB管理后台',
        d: '进行基础数据设置、员工管理、财务营收报表统计等功能。',
        i: 'iconxitongguanli',
      },
      {
        t: '门店收银系统',
        d: '实现销售收银、会员录入、开卡发卡等功能，支持主流收款方式。',
        i: 'iconcaiwuguanli',
      },
    ];
    return (
      <div className="onlineOutline">
        <div className="onlineOutline_wrapper">
          <div className="title">线上线下联动 轻松拥抱新零售</div>
          <div className="subtitle">玄掌柜提供全方位的产品矩阵，为您的经营保驾护航</div>
          {this.props.isMobile ? (
            <div className="content">
              <Row>
                {data.map(s => (
                  <Col key={s.t}>
                    <Right isMobile={this.props.isMobile} head={s.t} desc={s.d} icon={s.i} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="content">
              <Row>
                <Col span={10} offset={1}>
                  <Left head={data[0].t} desc={data[0].d} icon={data[0].i} />
                </Col>
                <Col span={10} offset={1} push={1}>
                  <Right
                    isMobile={this.props.isMobile}
                    head={data[1].t}
                    desc={data[1].d}
                    icon={data[1].i}
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={30}>
                <Col span={9}>
                  <Left
                    isMobile={this.props.isMobile}
                    head={data[2].t}
                    desc={data[2].d}
                    icon={data[2].i}
                  />
                </Col>
                <Col span={6}>
                  <img
                    width="100%"
                    alt="img"
                    className="img"
                    src="https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/juzhen.png"
                  />
                </Col>
                <Col span={9}>
                  <Right
                    isMobile={this.props.isMobile}
                    head={data[3].t}
                    desc={data[3].d}
                    icon={data[3].i}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OnlineOutline;
