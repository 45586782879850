import React from 'react';
import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
import MyIcon from '../components/MyIcon';

class OurService extends React.PureComponent {
  render() {
    return (
      <div className="ourservice">
        <div className="ourservice_title">服务是我们最好的产品</div>
        <div className="content">
          <Row>
            {[
              {
                icon: 'iconhuiyi',
                name: '培训支持',
                desc: '提供系统的培训资料，必要时，讲师也可登门培训',
              },
              {
                icon: 'icondangqianhuihua',
                name: '客服支持',
                desc: '7*24小时客服在线，响应速度快，解决效率高',
              },
              {
                icon: 'iconguzhangbaoxiu',
                name: '技术支持',
                desc: '强大的技术研发团队，不仅解决问题，更能让你敢想敢做',
              },
            ].map(s =>
              this.props.isMobile ? (
                <Col className="item" key={s.name}>
                  <Row type="flex" justify="center">
                    <Col span={5}>
                      <MyIcon
                        className="icon"
                        type={s.icon}
                        style={{ fontSize: 35, color: '#7C858F' }}
                      />
                    </Col>
                    <Col span={19}>
                      <p className="title">{s.name}</p>
                      <p className="subtitle">{s.desc}</p>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col span={8} className="item" key={s.name}>
                  <p className="title">{s.name}</p>
                  <p className="subtitle">{s.desc}</p>
                  <MyIcon
                    className="icon"
                    type={s.icon}
                    style={{ fontSize: 60, color: '#7C858F' }}
                  />
                </Col>
              )
            )}
          </Row>
        </div>
      </div>
    );
  }
}

export default OurService;
