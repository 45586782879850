import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo k3o6rzh9mys-editor_css',
    children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <>
                  <p>首页</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      // {
      //   name: 'item1',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <>
      //             <p>智慧经营</p>
      //           </>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      //   subItem: null,
      // },
      // {
      //   name: 'item2',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <>
      //             <p>解决方案</p>
      //           </>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      //   subItem: null,
      // },
      // {
      //   name: 'item3',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <>
      //             <p>价格</p>
      //           </>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      //   subItem: null,
      // },
      // {
      //   name: 'item4',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <>
      //             <p>我们</p>
      //           </>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      //   subItem: null,
      // },
      // {
      //   name: 'item5',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <>
      //             <p>FAQ</p>
      //           </>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      //   subItem: null,
      // },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1 k386fhkauk-editor_css' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper k3fihwszado-editor_css',
        },
        bg: { className: 'bg bg0 k385fhe7hg-editor_css' },
        title: {
          className: 'banner1-title k38ehp8muba-editor_css',
          children: '',
        },
        content: {
          className: 'banner1-content k386hb17qr-editor_css',
          children: (
            <>
              <p>
                <br />
              </p>
            </>
          ),
        },
        button: {
          className: 'banner1-button k3fim0oauxr-editor_css',
          children: (
            <>
              <p>立即免费试用</p>
            </>
          ),
          type: 'primary',
          href: '/apply',
          target: '_blank',
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper k3fiukit4f-editor_css',
        },
        bg: { className: 'bg bg1 k3fa76pn66g-editor_css' },
        title: {
          className: 'banner1-title k3fiucxi4eu-editor_css',
          children: '',
        },
        content: {
          className: 'banner1-content k386irbte2k-editor_css',
          children: (
            <>
              <p>
                <br />
              </p>
            </>
          ),
        },
        button: {
          className: 'banner1-button k3n1sk48p5k-editor_css',
          children: (
            <>
              <p>立即免费试用</p>
            </>
          ),
          href: '/apply',
          type: 'primary',
          target: '_blank',
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper k3fitcl826-editor_css',
        },
        bg: { className: 'bg bg1 k3fhx5agusd-editor_css' },
        title: {
          className: 'banner1-title k3fisxor46-editor_css',
          children: '',
        },
        content: {
          className: 'banner1-content k386ixw2lt-editor_css',
          children: (
            <>
              <p>
                <br />
              </p>
            </>
          ),
        },
        button: {
          className: 'banner1-button k3n1u6nyqf-editor_css',
          children: (
            <>
              <p>立即免费试用</p>
            </>
          ),
          href: '/apply',
          type: 'primary',
          target: '_blank',
        },
      },
    ],
    type: ['across'],
    autoPlay: true,
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k3fhd01lzcl-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/1.png',
    className: 'k3fhduqfhp9-editor_css',
  },
  textWrapper: {
    className: 'content1-text k3o710emjir-editor_css',
    md: 14,
    xs: 24,
  },
  title: {
    className: 'content1-title k39xw59jhh-editor_css',
    children: (
      <>
        <p>母婴零售 店铺经营场景一体化解决方案</p>
        <p>
          <br />
        </p>
      </>
    ),
  },
  content: {
    className: 'content1-content k3n2e8t4xde-editor_css',
    children: (
      <>
        <h3>线上冲击大，吸引客流难？</h3>
        <p>灵活营销，拉新、转化、复购一一解决</p>
        <p>
          <br />
        </p>
        <h3>内场坪效低，难以盈利？</h3>
        <p>多终端商品库存管理，批量操作提高效率</p>
        <p>
          <br />
        </p>
        <h3>打折不好做，不小心亏本？</h3>
        <blockquote>深层次计算营销成本，是赚是亏一目了然</blockquote>
        <p>
          <br />
        </p>
        <p>
          <a href="/muying" target="_blank">
            了解更多功能 &gt;&gt;
          </a>
        </p>
      </>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper k383rqndgc7-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/2.png',
    className: 'k3fi2fz1xz-editor_css',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title k39y0e95h4-editor_css',
    children: (
      <>
        <p>亲子游乐 联合经营一体化解决方案</p>
        <p>
          <br />
        </p>
      </>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <>
        <h3>店铺经营情况不清晰？</h3>
        <blockquote>支持单店铺/多店铺（总部监控），店铺内各个游乐项目的盈利情况分析</blockquote>
        <p>
          <br />
        </p>
        <h3>客人缺乏粘性，哪里便宜去哪里？</h3>
        <blockquote>提供丰富的会员营销体系，拉新、促活、留存齐头并进</blockquote>
        <p>
          <br />
        </p>
        <h3>面对潜在客户无从下手？</h3>
        <blockquote>
          OTA销售渠道对接，老带新会员裂变等，线上+线下多种引流方式帮助门店增加客源
        </blockquote>
        <p>
          <br />
        </p>
        <p>
          <a href="/qinzi" target="_blank">
            了解更多功能 &gt;&gt;
          </a>
        </p>
      </>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k3fi61foupj-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/3.png',
    className: 'k3fi57djt9b-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title k39y12szyp6-editor_css',
    children: (
      <>
        <p>电玩娱乐 软硬件结合的多场景解决方案</p>
        <p>
          <br />
        </p>
      </>
    ),
  },
  content: {
    className: 'content1-content k3n2fldl94f-editor_css',
    children: (
      <>
        <h3>投币买币太辛苦，赚的不如投入的人力多？</h3>
        <blockquote>轻便刷卡头，存币扣币线上化，解决传统的电玩行业烦恼</blockquote>
        <p>
          <br />
        </p>
        <h3>彩票计算复杂，数据统计困难，机器盈亏、赚钱赔钱不知道？</h3>
        <blockquote>实时数据统计，线上管理彩票资产，机台盈亏、彩票数据一目了然</blockquote>
        <p>
          <br />
        </p>
        <h3>系统全切换太麻烦，找不到合适的卡头提供商？</h3>
        <blockquote>开放的物联网接口，可以现有业务系统对接，低成本无缝切换</blockquote>
        <p>
          <br />
        </p>
        <p>
          <a href="/dianwan" target="_blank">
            了解更多功能 &gt;&gt;
          </a>
        </p>
      </>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper k3841gz0fw-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/4.png',
    className: 'k3fi6bs198k-editor_css',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title k39y1txrqum-editor_css',
    children: (
      <>
        <p>混合业态 多业态商业场景解决方案</p>
        <p>
          <br />
        </p>
      </>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <>
        <h3>多业务混合难以管理？</h3>
        <blockquote>商品管理、分类管理、库存管理相结合，让商品管理更简单。</blockquote>
        <p>
          <br />
        </p>
        <h3>业务如何有效结合良性互动？</h3>
        <blockquote>多场景促销方案可供可供选择，促进客户消费留存。</blockquote>
        <p>
          <br />
        </p>
        <h3>如何确认成本，确认收入？</h3>
        <blockquote>数据报表可视化，经营情况一目了然。</blockquote>
        <p>
          <br />
        </p>
        <p>
          <a href="/hunhe" target="_blank">
            了解更多功能 &gt;&gt;
          </a>
        </p>
      </>
    ),
  },
};
export const Content52DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper k3o6fbwvl6j-editor_css',
  },
  page: { className: 'home-page content5 k3o6f598f0f-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>他们都在用</p>
          </>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/1huarenwenhua.png',
            className: 'k3nuxrah8gp-editor_css',
          },
          content: {
            children: (
              <>
                <p>华人文化</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/2yoyotu.png',
            className: 'k3nuy0qnlba-editor_css',
          },
          content: {
            children: (
              <>
                <p>悠游堂</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/3taotaochengbao.png',
            className: 'k3nuywz2dn-editor_css',
          },
          content: {
            children: (
              <>
                <p>桃桃城堡</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content k3o6g1rctb8-editor_css',
          },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/4ttw.png',
            className: 'k3nuz4k1tps-editor_css',
          },
          content: {
            children: (
              <>
                <p>甜甜湾</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/5qidian.png',
            className: 'k3nuzgr3dgf-editor_css',
          },
          content: {
            children: (
              <>
                <p>启点儿童娱乐</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/6kidsland.png',
            className: 'k3nuzp0674a-editor_css',
          },
          content: {
            children: (
              <>
                <p>凯知乐玩具体验</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/7bamai.png',
            className: 'k3nuzwxgor-editor_css',
          },
          content: {
            children: (
              <>
                <p>八迈卡丁车俱乐部</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/logo/8mengdou.png',
            className: 'k3nv05hhi8-editor_css',
          },
          content: {
            children: (
              <>
                <p>萌逗有机厨房</p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children: 'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>玄掌柜 全部功能 均可免费试用</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>包括母婴零售 、亲子游乐、电玩娱乐、混合业态等各类解决方案，更有产品专家</p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <>
            <p>远程协助，为您答疑解惑</p>
          </>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '/apply',
        children: (
          <>
            <p>立即免费试用</p>
          </>
        ),
        type: 'primary',
        target: '_blank',
      },
    },
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block2',
        xs: 24,
        md: 16,
        className: 'block',
        title: {
          children: (
            <>
              <p>联系我们</p>
            </>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '',
              name: 'link0',
              children: (
                <>
                  <p>联系电话：400-611-0210</p>
                  <p>邮件地址：chhuang@storeteam.cn</p>
                  <p>寄件地址：南京市雨花台区大周路88号软件谷科创城B2幢</p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 8,
        className: 'block k6sbfxbn316-editor_css',
        title: {
          children: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/gongzhonghao.jpg',
          className: 'k6sbfxupdcm-editor_css',
        },
        childWrapper: {
          children: [
            {
              name: 'link1',
              className: 'k6sbfxbn326-editor_css',
              children: (
                <>
                  <p>扫码关注公众号</p>
                  <p>了解更多经营秘笈</p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <p>
          ©2018 by 南京一玄信息科技有限公司（
          <a
            href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏ICP备18011861号
          </a>
          ）&nbsp;All Rights Reserved
        </p>
      </>
    ),
  },
};
