/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav3 from './Nav3';
import Banner1 from './Banner1';
// import Feature2 from './Feature2';
// import Feature1 from './Feature1';
// import Content5 from './Content5';
// import Content11 from './Content11';
// import Footer1 from './Footer1';
import Function1 from './Function1';
import OnlineOutline from './OnlineOutline';
import Footer from './Footer';
import OurService from './OurService';
import OurUser from './OurUser';
import OurData from './OurData';
import OurMachine from './OurMachine';
import OurMarket from './OurMarket';

import {
  Nav30DataSource,
  Banner10DataSource,
  // Content10DataSource,
  // Feature20DataSource,
  // Feature10DataSource,
  // Feature21DataSource,
  // Content52DataSource,
  // Content110DataSource,
  Footer11DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav3 id="Nav3_0" key="Nav3_0" dataSource={Nav30DataSource} isMobile={this.state.isMobile} />,
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={Banner10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Function1 id="Function1_0" key="Function1_0" isMobile={this.state.isMobile} />,
      <OnlineOutline id="OnlineOutline_0" key="OnlineOutline_0" isMobile={this.state.isMobile} />,
      <OurMarket id="OurMarket_0" key="OurMarket_0" isMobile={this.state.isMobile} />,
      <OurMachine id="OurMachine_0" key="OurMachine_0" isMobile={this.state.isMobile} />,
      <OurData id="OurData_0" key="OurData_0" isMobile={this.state.isMobile} />,
      <OurUser id="OurUser_0" key="OurUser_0" isMobile={this.state.isMobile} />,
      <OurService id="OurService_0" key="OurService_0" isMobile={this.state.isMobile} />,
      <Footer
        id="Footer_1"
        key="Footer_1"
        dataSource={Footer11DataSource}
        isMobile={this.state.isMobile}
      />,

      // <Feature2
      //   id="Feature2_0"
      //   key="Feature2_0"
      //   dataSource={Feature20DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Feature1
      //   id="Feature1_0"
      //   key="Feature1_0"
      //   dataSource={Feature10DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Feature2
      //   id="Feature2_1"
      //   key="Feature2_1"
      //   dataSource={Feature21DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Content5
      //   id="Content5_2"
      //   key="Content5_2"
      //   dataSource={Content52DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Content11
      //   id="Content11_0"
      //   key="Content11_0"
      //   dataSource={Content110DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={d => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
