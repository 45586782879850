import React from 'react';
// import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
// import MyIcon from '../components/MyIcon';

class OurMachine extends React.PureComponent {
  render() {
    return (
      <div className="ourmachine">
        <div className="ourmachine_wrapper">
          <div className="title">物联网设备 软硬结合打造智慧门店</div>
          <div className="subtitle">
            玄掌柜提供POS/PAD收银设备、电玩刷卡头、智能门禁、感应器等智能设备，全面实现门店升级
          </div>
          {this.props.isMobile && (
            <div className="subtitle">
              一玄“云盒”“星盒（二代）”均有外观设计专利，并且开放物联网接口，可用最小成本与现有系统对接。线上存币、刷卡扣币，降低人力维护成本。
            </div>
          )}
          <div className="content">
            <div className="content_wrapper">
              {[
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/pad.png',
                  width: 45,
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/pos.png',
                  width: 25,
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/card.png',
                  width: 30,
                },
              ].map(s => (
                <div className="item" key={s.img} style={{ width: `${s.width}%` }}>
                  <img className="img" src={s.img} width="100%" alt="img" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurMachine;
