import React from 'react';
// import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
import MyIcon from '../components/MyIcon';

class Function1 extends React.PureComponent {
  render() {
    return (
      <div className="function1">
        <div className="title">功能多样 全面覆盖门店运营日常需求</div>
        <div className="subtitle">多年行业沉淀，功能简单易用，且贴合用户实际使用场景</div>
        <div className="content">
          <div className="content_wrapper">
            {[
              { icon: 'iconservice', name: '商品管理' },
              { icon: 'iconbarrage', name: '票卡管理' },
              { icon: 'icontasklist', name: '套餐销售' },
              { icon: 'iconlabel', name: '预储值' },
              { icon: 'iconsupply', name: '库存管理' },
              { icon: 'iconlike', name: '会员管理' },
              { icon: 'iconintegral', name: '积分商城' },
              { icon: 'iconselect', name: '勋章体系' },
              { icon: 'iconbusinesscard', name: '资产管理' },
              { icon: 'icongroup', name: '群发信息' },
              { icon: 'iconcoupons', name: '优惠券' },
              { icon: 'iconflashlight', name: '微信预约' },
              { icon: 'iconflag', name: '电玩彩票' },
              { icon: 'icontools', name: '营销工具' },
              { icon: 'iconhomepage', name: '线上商城' },
              { icon: 'iconcreatetask', name: '财务报表' },
              { icon: 'icondynamic', name: '交接班报表' },
              { icon: 'iconaddressbook', name: '店员分销' },
              { icon: 'iconkeyboard', name: '毛利率报表' },
              { icon: 'iconmanage', name: '更多' },
            ]
              .filter((s, i) => !this.props.isMobile || i < 18)
              .map(s => (
                <div className="item" key={s.icon}>
                  <MyIcon
                    className="icon"
                    type={s.icon}
                    style={{ fontSize: 40, color: '#7C858F' }}
                  />
                  <p className="name">{s.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Function1;
