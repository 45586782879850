import React from 'react';
// import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
// import MyIcon from '../components/MyIcon';

class OurService extends React.PureComponent {
  render() {
    return (
      <div className="ouruser">
        <div className="ouruser_wrapper">
          <div className="title">他们都在用</div>
          <div className="content">
            <div className="content_wrapper">
              {[
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/yuou.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/taotao.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/huaren.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/8mile.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/yuyuto.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/tongqu.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/ttw.png',
                },
                {
                  img:
                    'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/kidsland.png',
                },
                {
                  img: 'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/qidian.png',
                },
                {
                  img:
                    'https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/userlogo/mengdou.png',
                },
              ]
                .filter((s, i) => (this.props.isMobile ? i < 9 : true))
                .map(s => (
                  <div className="item" key={s.img}>
                    <img className="img" src={s.img} width="100%" alt="img" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurService;
