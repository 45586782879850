import React from 'react';
import { Icon } from 'antd';

const MIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1183080_a7gajk964tu.js', // 在 iconfont.cn 上生成
});

class MyIcon extends React.PureComponent {
  render() {
    return <MIcon {...this.props} />;
  }
}

export default MyIcon;
