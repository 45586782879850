import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu, Row, Col, Button } from 'antd';
import { getChildrenToRender } from './utils';

const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map(item => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div {...a} className={`header3-item-block ${a.className}`.trim()}>
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>{childItem.children.map(getChildrenToRender)}</a>
              ) : (
                <div {...childItem}>{childItem.children.map(getChildrenToRender)}</div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        {isMobile ? (
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
          >
            <TweenOne
              animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
              {...dataSource.logo}
            >
              <img width="100%" src={dataSource.logo.children} alt="img" />
            </TweenOne>
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
            <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      x: 0,
                      height: 0,
                      duration: 300,
                      onComplete: e => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = 'auto';
                        }
                      },
                      ease: 'easeInOutQuad',
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? 'inline' : 'horizontal'}
                defaultSelectedKeys={['item0']}
                theme="dark"
              >
                {navChildren}
              </Menu>
            </TweenOne>
          </div>
        ) : (
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
          >
            <Row type="flex" justify="center" align="middle">
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <TweenOne
                  animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                  {...dataSource.logo}
                >
                  <img width="100%" src={dataSource.logo.children} alt="img" />
                </TweenOne>
              </Col>
              {isMobile && (
                <div
                  {...dataSource.mobileMenu}
                  onClick={() => {
                    this.phoneClick();
                  }}
                >
                  <em />
                  <em />
                  <em />
                </div>
              )}
              <Col xs={16} sm={4} md={16} lg={16} xl={16}>
                <TweenOne
                  {...dataSource.Menu}
                  animation={
                    isMobile
                      ? {
                          x: 0,
                          height: 0,
                          duration: 300,
                          onComplete: e => {
                            if (this.state.phoneOpen) {
                              e.target.style.height = 'auto';
                            }
                          },
                          ease: 'easeInOutQuad',
                        }
                      : null
                  }
                  moment={moment}
                  reverse={!!phoneOpen}
                >
                  <Menu
                    mode={isMobile ? 'inline' : 'horizontal'}
                    defaultSelectedKeys={['item0']}
                    theme="dark"
                  >
                    {navChildren}
                  </Menu>
                </TweenOne>
              </Col>
              {!isMobile && (
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Button href="http://m.xzg.storeteam.cn" target="_blank">
                    登录
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: 20 }}
                    href="http://m.xzg.storeteam.cn"
                    target="_blank"
                  >
                    免费试用
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        )}
      </TweenOne>
    );
  }
}

export default Header3;
