import React from 'react';
import { Row, Col } from 'antd';
// import { TweenOneGroup } from 'rc-tween-one';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import { getChildrenToRender } from './utils';
import MyIcon from '../components/MyIcon';

class OurService extends React.PureComponent {
  render() {
    return (
      <div className="ourdata">
        <div className="ourdata_wrapper">
          <div className="title">数据挖掘 全方位报表分析经营状况</div>
          <div className="subtitle">
            玄掌柜重视数据挖掘与分析，面向老板、运营、营销、财务等经营中的不同环节产出针对性强的数据报表，为业务提供强大数据支撑
          </div>
          <div className="content">
            <Row type="flex" gutter={36}>
              <Col
                xs={{ span: 24, order: this.props.isMobile ? 2 : 1 }}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <img
                  src="https://yixuanbase.oss-cn-hangzhou.aliyuncs.com/shouye/report.png"
                  className="img"
                  width="100%"
                  alt="img"
                />
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={12} md={12} lg={12} xl={12}>
                <div className="desc">
                  移动端实时数据、PC端分析对比数据和明细数据，多终端、多维度，随时随地全局掌握门店经营。
                </div>
                <Row>
                  {[
                    { icon: 'iconkeyanjingfei', name: '销售数据' },
                    { icon: 'iconfaqirenwuxietong', name: '收银对账' },
                    { icon: 'iconzhihuitiku', name: '收入确认' },
                    { icon: 'iconjiashicang', name: '营销分析' },
                    { icon: 'iconketangjiankong', name: '资产统计' },
                    { icon: 'iconxueshenghuaxiang', name: '会员画像' },
                    { icon: 'iconkechenghuaxiang', name: '电玩机台报表' },
                    { icon: 'iconrenwuzhongxin', name: '库存统计' },
                  ].map(s => (
                    <Col span={6} className="item" key={s.name}>
                      <MyIcon
                        className="icon"
                        type={s.icon}
                        style={{ fontSize: 40, color: '#338BF9' }}
                      />
                      <p className="name">{s.name}</p>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OurService;
