import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import Home from './Home';
// import MuYing from './Muying';
// import QinZi from './Qinzi';
// import DianWan from './DianWan';
// import HunHe from './HunHe';
// import Apply from './Apply';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <div>
      <Route exact path="/" component={Home} />
      {/* <Route exact path="/muying" component={MuYing} />
      <Route exact path="/qinzi" component={QinZi} />
      <Route exact path="/dianwan" component={DianWan} />
      <Route exact path="/hunhe" component={HunHe} />
      <Route exact path="/apply" component={Apply} /> */}
    </div>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
